import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import RegisterButton from '~containers/landing/RegisterButton';
import LandingPageTitle from '~components/LandingPageTitle';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '106px',
    gap: 60,
    [theme.breakpoints.down('xs')]: {
      gap: 28,
      paddingTop: '64px'
    }
  },
  section: {
    position: 'relative',
    marginBottom: '-10px'
  },
  bgImage: {
    width: '100%'
  }
}));

const LargeImageCta = ({
  cta_rich_title,
  background_image,
  background_image_mobile,
  text_color,
  cta_theme
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const bgImage = useMemo(() => {
    if (isMobile && background_image_mobile?.url) {
      return background_image_mobile;
    }
    return background_image;
  }, [isMobile, background_image, background_image_mobile]);

  return (
    <div className={classes.section}>
      <img src={bgImage?.url} alt={bgImage?.alt} className={classes.bgImage} />
      <div className={classes.container}>
        <LandingPageTitle
          title={cta_rich_title}
          fontSize={74}
          fontSizeXS={40}
          color={text_color}
          maxWidth="1184px"
          maxWidthXS="341px"
        />
        <RegisterButton
          size="large"
          variant={cta_theme}
          analyticsTitle={cta_rich_title?.text}
          analyticsSection="Large Image CTA"
        />
      </div>
    </div>
  );
};

LargeImageCta.propTypes = {
  cta_rich_title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  background_image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  background_image_mobile: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  text_color: PropTypes.string,
  cta_theme: PropTypes.string
};

LargeImageCta.defaultProps = {
  background_image_mobile: null,
  text_color: '#FFFFFF',
  cta_theme: 'white'
};

export default LargeImageCta;
