import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  titleContainer: props => ({
    maxWidth: props.maxWidth || '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: props.maxWidthXS || '100%'
    }
  }),
  header: props => ({
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      color: props.color || '#000000',
      fontSize: props.fontSize,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '118%',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: props.fontSizeXS
      }
    },
    '& > * > strong': {
      color: props.boldColor || props.color || '#000000',
      fontWeight: '600'
    }
  })
}));

const LandingPageTitle = ({ title, ...restStyles }) => {
  const classes = useStyles(restStyles);

  return (
    <div className={classes.titleContainer}>
      <RichText html={title?.html} externalClassName={classes.header} verticalSpacing={0} />
    </div>
  );
};

LandingPageTitle.propTypes = {
  title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  color: PropTypes.string,
  boldColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontSizeXS: PropTypes.string,
  maxWidth: PropTypes.string,
  maxWidthXS: PropTypes.string
};

LandingPageTitle.defaultProps = {
  color: '#000000',
  boldColor: '#000000',
  fontSize: 64,
  fontSizeXS: 32,
  maxWidth: '100%',
  maxWidthXS: '100%'
};

export default LandingPageTitle;
